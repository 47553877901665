<template>
  <div class="flex direction center home_page">
    <div class="flex member_msg_content" v-if="selfMsg.id">
      <div class="flex center user_icon">
        <div class="flex center user_default_content" ref="adsBtn" @click="authShowEvent">ADS</div>
        <div class="user_icon_main">
          <img src="../assets/user_icon.png" class="user_icon" alt="">
        </div>
      </div>
      <div class="user_msg_content">
        <div class="user_name">Player name</div>
        <div class="flex user_msg">
          <div class="user_msg_item">Debt: 1000</div>
          <div class="user_msg_item">Return: 1000</div>
        </div>
      </div>
      <div class="flex end align_start join_btn_content">
        <div class="flex center join_btn">
          <img src="../assets/share.png" class="share_icon" alt="">
        </div>
      </div>
    </div>
    <div class="flex direction center member_msg_content" v-else>
      <div class="un_mini_tip">You don't have mini App yet</div>
      <div class="un_mini_tip">Click to <span class="get_color">Claim mini App</span></div>

    </div>

    <div class="flex tab">
      <div class="flex tab_main">
        <div class="tab_btn" :style="{left:'calc(100% / 3 *'+activeIndex+' + 0.1rem)'}"></div>
        <div :class="['flex center tab_item', activeIndex == 0 && 'active']" @click="changeTabEvent(0)">Square</div>
        <div :class="['flex center tab_item', activeIndex == 1 && 'active']" @click="changeTabEvent(1)">Debt</div>
        <div :class="['flex center tab_item', activeIndex == 2 && 'active']" @click="changeTabEvent(2)">Return</div>
      </div>
    </div>
    <div class="list_content" ref="listContent">
      <template v-if="(selfMsg.id || activeIndex == 0) && dataList.length > 0">
        <div class="flex list_item" v-for="(item,index) in 10" :key="index">
          <div class="flex center user_icon">
            <div class="flex center user_default_content">ADS</div>
            <div class="user_icon_main">
              <img src="../assets/user_icon.png" class="user_icon" alt="">
            </div>
          </div>
          <div class="user_msg_content">
            <div class="user_name">Player name</div>
            <div class="flex user_msg">
              <div class="user_msg_item">Debt: 1000</div>
              <div class="user_msg_item">Return: 1000</div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="selfMsg.id && dataList.length <= 0">
        <div class="un_data_tip" v-if="activeIndex == 1">Currently no ads have been shown to help you. <br /> Go and <span class="link_active">Invite</span> your friends to watch.</div>
        <div class="un_data_tip" v-if="activeIndex == 2">You have no pending viewing history.<br /><span class="link_active">Get more revenue</span></div>
      </template>
      <template v-if="!selfMsg.id && activeIndex != 0">
        <div class="un_data_tip">
          You don't have your own advertising mini App yet.<br />
          Click to <span class="link_active">Claim Mini App</span>, and all advertising revenue will belong to you
        </div>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomePage',
  components: {},
  data() {
    return {
      activeIndex: 1,
      selfMsg: { id: 1 },
      dataList: [],
    }
  },
  mounted() {

    this.$nextTick(() => {
      let showBtn = this.$refs.adsBtn;
      showBtn.click()
      let content = this.$refs.listContent
      let contentMsg = content.getBoundingClientRect();
      content.style.height = 'calc(100vh - ' + contentMsg.top + 'px - 1rem)';
    })
  },
  methods: {
    authShowEvent() {
      window.adController.show().then((result) => {
        console.log(result)
        setTimeout(() => {
          this.$refs.adsBtn.click()
          // window.Telegram.WebApp.close()
        }, 1000);
      }).catch((err) => {
        console.log('err=====', err)
        setTimeout(() => {
          this.$refs.adsBtn.click()
        }, 1000);
      })
    },
    changeTabEvent(index) {
      this.activeIndex = index;
    },
    shareEvent() {
      let shareSt = `💰Come and make money together🎉\n🤩Get your exclusive Mini App🎊\n🎁All advertising revenue is yours💰`;
      console.log(shareSt)
      // let share_url = `http://t.me/unityaubot/uadu?`;
      // window.Telegram.WebApp.openTelegramLink(share_url)
    }
  }
}
</script>
<style lang="scss" scoped>
.home_page {
  padding: 1rem;
  box-sizing: border-box;
}
.member_msg_content {
  padding: 0.8rem;
  box-sizing: border-box;
  background-color: rgba($color: #fff, $alpha: 0.5);
  border-radius: 0.5rem;
  width: 100%;
  position: relative;
  .un_mini_tip {
    color: #fff;
    font-size: 0.8rem;
    line-height: 1.2rem;
    .get_color {
      color: #a2d3f5;
      font-weight: 900;
      text-decoration: underline;
    }
  }

  &.member_msg_content::before {
    content: "";
    position: absolute;
    top: 0.3rem;
    left: 0.3rem;
    width: calc(100% - 0.6rem);
    height: calc(100% - 0.6rem);
    border: 0.1rem solid rgba($color: #ccc, $alpha: 0.5);
    box-sizing: border-box;
    border-radius: 0.5rem;
    // background-color: rgba($color: #fff, $alpha: 0.3);
    // z-index: -1;
  }
  .user_icon {
    width: 3rem;
    height: 3rem;
    // padding: 0.15rem;
    box-shadow: 0 0 0.1rem #ccc;
    border: 0.1rem solid #ccc;
    box-sizing: border-box;
    border-radius: 50%;
    position: relative;
    .user_default_content {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      color: #fff;
      font-size: 0.8rem;
      font-weight: bold;
      background-color: #000;
    }
    .user_icon_main {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    .user_icon {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .user_msg_content {
    width: calc(100% - 5rem);
    padding-left: 0.4rem;
    box-sizing: border-box;
    .user_name {
      font-size: 1rem;
      font-weight: bold;
      width: 90%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #fff;
      margin-bottom: 0.5rem;
    }
    .user_msg {
      font-size: 0.75rem;
      font-weight: bold;
      color: #fff;
      .user_msg_item {
        margin-right: 1rem;
        // width: 50%;
      }
    }
  }
  .join_btn_content {
    width: 2rem;
    height: 3rem;
    position: relative;
    z-index: 10;
    .join_btn {
      width: 1.7rem;
      height: 1.7rem;
      border-radius: 50%;
      background-color: rgba($color: #ccc, $alpha: 0.5);
      .share_icon {
        width: 1rem;
      }
    }
  }
}

.tab {
  width: calc(100% - 3rem);
  height: 2rem;
  background-color: #000;
  border-radius: 1.25rem;
  margin-top: 0.8rem;
  position: relative;
  border: 0.1rem solid rgba($color: #fff, $alpha: 0.5);
  .tab_main {
    border-radius: 1.25rem;
    background-color: #000;
    width: 100%;
    height: 100%;
    z-index: 3;
    .tab_btn {
      width: calc(100% / 3 - 0.2rem);
      position: absolute;
      left: 0.1rem;
      top: 0.15rem;
      height: calc(100% - 0.3rem);
      background-color: #fff;
      border-radius: 1.25rem;
      transition: all 0.3s;
    }
    .tab_item {
      width: calc(100% / 3);
      color: #888;
      position: relative;
      z-index: 10;
      font-size: 0.8rem;
      transition: all 0.3s;
      font-weight: 900;
      &.active {
        color: #000;
      }
    }
  }
}

.link_active {
  color: #18b92b;
  font-weight: 900;
  text-decoration: underline;
}
.list_content {
  margin-top: -1rem;
  width: 100%;
  border: 0.1rem solid rgba($color: #fff, $alpha: 0.5);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  height: 60vh;
  padding-top: 1.5rem;
  overflow-y: scroll;
  .un_data_tip {
    color: #fff;
    height: 100%;
    font-size: 0.9rem;
    padding-top: 10%;
    line-height: 1.5rem;
    text-align: center;
  }
  .list_item {
    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);
    padding: 0.5rem 0;
    box-sizing: border-box;
  }
  .user_icon {
    width: 3rem;
    height: 3rem;
    // padding: 0.15rem;
    box-shadow: 0 0 0.1rem #ccc;
    border: 0.1rem solid #ccc;
    box-sizing: border-box;
    border-radius: 50%;
    position: relative;
    .user_default_content {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      color: #fff;
      font-size: 0.8rem;
      font-weight: bold;
      background-color: #000;
    }
    .user_icon_main {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    .user_icon {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .user_msg_content {
    width: calc(100% - 3rem);
    padding-left: 0.4rem;
    box-sizing: border-box;
    .user_name {
      font-size: 1rem;
      font-weight: bold;
      width: 90%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #fff;
      margin-bottom: 0.5rem;
    }
    .user_msg {
      font-size: 0.75rem;
      font-weight: bold;
      color: #fff;
      .user_msg_item {
        margin-right: 1rem;
        // width: 50%;
      }
    }
  }
}
</style>
