<template>
  <div class="root_bg"></div>
  <div class="root"><router-view /></div>
</template>

<style lang="scss">
.root_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba($color: #000000, $alpha: 0.6);
  z-index: 1;
}
.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  z-index: 2;
}
.flex{
  display: flex;
  align-items: center;
  &.align_start{
    align-items: flex-start;
  }
  &.align_end{
    align-items: flex-end;
  }
  &.center{
   justify-content: center;
  }
  &.end{
    justify-content: flex-end;
  }
  &.between{
    justify-content: space-between;
  }
  &.direction{
    flex-direction: column;
  }
}
</style>
